import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePageVisibility } from "../../hooks/usePageVisibility";
import { UserChatMessage } from "../../components/UserChatMessage";
import { Answer, AnswerLoading } from "../../components/Answer";
import { ClearChatButton } from "../../components/ClearChatButton";
import { QuestionInput } from "../../components/QuestionInput";
import NswIcon from "../../assets/nswIcon";
import ServiceNSWLogo from "../../assets/serviceNSWLogo";
import { ChatAppResponse, feedbackApi, ConversationEntry, makeApiRequest, saveConversation, fetchPDF, isFeedbackBtnType, setBackgroundProcessing } from "../../api";
import { ExampleList } from "../../components/Example";
import styles from "./Chat.module.css";
import session from "../../session";
import { RAGAgent } from "../../api/models";

const ChatHome: React.FC<{ onQuestion: (question: string) => void }> = ({ onQuestion }) => (
  <div className={styles.chatEmptyState}>
    <ServiceNSWLogo w={"177"} h={"61"} />
    <h3>How can I help you today?</h3>
    <ExampleList onExampleClicked={onQuestion} />
  </div>
);

const Chat: React.FC = () => {
  const [conversation, setConversation] = useState<ConversationEntry[]>([]);
  const [generating, setGenerating] = useState<boolean>(false);
  const [inside, setInside] = useState<boolean>(false);
  const [isChatHome, setChatHome] = useState<boolean>(false);
  const [hasAnswer, setHasAnswer] = useState<boolean>(false);
  const [pdfCache, setPdfCache] = useState<{ [key: string]: string }>({});
  const [inputFocusToggle, setInputFocusToggle] = useState<boolean>(false);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateConversationState = (conversation: ConversationEntry[]) => {
    session.saveConversationEntries(conversation);
    setConversation(conversation);
  };

  useEffect(() => {
    setChatHome(!generating && conversation.length === 0);
    isChatHome ? setInside(false) : {};
    setHasAnswer(!!conversation[conversation.length - 1]?.[1]);
    setInputFocusToggle(!inputFocusToggle);
    scrollToBottom();
  }, [generating, conversation, hasAnswer]);

  useEffect(() => {
    const loadConversationHistory = async () => {
      const conversationHistory = session.getConversationEntries();
      if (conversationHistory) {
        setConversation(conversationHistory);
      }
    };
    loadConversationHistory();
  }, []);

  const clearChat = () => {
    session.clear();
    session.start();
    setInside(false);
    setConversation([]);
  };

  const onInputQuestion = async (q: string) => {
    if (generating) return;
    setGenerating(true);
    setConversation([...conversation, [q]]);

    const response = await makeApiRequest(conversation, q, updateConversationState);
    saveConversation(response, q);
    updateConversationState(response);
    setGenerating(false);
  };

  const onCitationClicked = useCallback(
    async (citationPath: string) => {
      if (generating) return;

      const openInNewWindow = (url: string) => window.open(url, "_blank", "noopener,noreferrer");

      if (citationPath.indexOf(".pdf") > -1) {
        const file = `${import.meta.env.VITE_USU_CONTENT_BASE_URL}/sfr.html?file=${citationPath.replace(/^\/data\//, "")}`;
        console.log(citationPath);
        openInNewWindow(file);
        return;
      } else if (citationPath.startsWith('https://')) {
        openInNewWindow(citationPath);
        return;
      }

      if (citationPath.startsWith(`${import.meta.env.VITE_LEGISLATION_WEBSITE_BASE_URL}`)) {
        openInNewWindow(citationPath);
        return;
      }

      if (citationPath.startsWith("/data/usu_")) {
        const file = `${import.meta.env.VITE_USU_CONTENT_BASE_URL}/${citationPath.replace(/^\/data\/usu_/, "")}`;
        openInNewWindow(file);
        return;
      }
    },
    [pdfCache]
  );

  const saveChatFeedback = (botResponseId: string) => async (feedback: string) => {
    if (generating) {
      return new Promise<void>(() => { });
    }

    const feedbackData = isFeedbackBtnType(feedback) ? { type: feedback } : { reason: feedback };

    return feedbackApi({ id: botResponseId, feedback: feedbackData }).then(() => {
      session.updateChatFeedback(botResponseId, feedbackData);
    });
  };

  const isComplete = (d: ChatAppResponse) => !!d?.choices[0]?.id;

  const handleMouseEnter = () => setInside(true);
  const handleMouseLeave = () => setInside(false);

  const renderedAnswers = conversation.map((answer, index) => {
    return (
      <div className={styles.chatMessageWrapper} key={index}>
        <UserChatMessage message={answer[0]} />
        <div className={styles.chatMessageGpt}>
          {answer[1] && (
            <Answer
              answer={answer[1]}
              isStreaming={generating}
              selected_agent={answer[1].selected_agent as RAGAgent}
              showFeedback={isComplete(answer[1])}
              getFeedbackData={() => session.getChatFeedbackData(answer[1]?.choices[0]?.id ?? "")}
              onCitationClicked={onCitationClicked}
              onFeedbackClicked={saveChatFeedback(answer[1].choices[0].id)}
            />
          )}
        </div>
        <div ref={messagesEndRef} />
      </div>
    );
  });

  const maybeGenerating = !isChatHome && !hasAnswer && (
    <div className={styles.chatMessageGptMinWidth}>
      <AnswerLoading />
    </div>
  );

  const showNewChatButton = ((!isChatHome && generating) || (!generating && hasAnswer)) && (
    <div className={styles.chatHeader}>
      <div className={styles.chatHeaderMenu}>
        <div className={styles.chatRefresh} onClick={clearChat}>
          <ServiceNSWLogo w={"123"} h={"63"} />
        </div>
        <ClearChatButton onClick={clearChat} disabled={generating} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} isInside={inside} />
      </div>
    </div>
  );

  const content = isChatHome ? <ChatHome onQuestion={onInputQuestion} /> : renderedAnswers;

  // Use the page visibility API to detect when the page is visible/hidden
  const isVisible = usePageVisibility();
  
  // Update the background processing flag when visibility changes
  useEffect(() => {
    // Set the background processing flag based on visibility
    setBackgroundProcessing(!isVisible);
    
    // If the page becomes visible and we have content being generated,
    // force a re-render to show the latest content
    if (isVisible && generating) {
      setInputFocusToggle(prev => !prev);
    }
  }, [isVisible, generating]);

  return (
    <>
      <div className={styles.container}>
        {showNewChatButton}
        <div className={styles.chat}>
          <div className={styles.chatMessageBody}>
            {content}
            {maybeGenerating}
            <div ref={chatMessageStreamEnd} />
          </div>
        </div>
        <div className={styles.chatInput}>
          <QuestionInput
            clearOnSend
            placeholder="Message Sage..."
            disabled={generating}
            onSend={onInputQuestion}
            onFocus={isChatHome || hasAnswer}
            triggerFocus={inputFocusToggle}
          />
        </div>
        <p className={styles.footNote}>Sage can make mistakes. Read and check important info.</p>
      </div>
    </>
  );
};

export default Chat;
