import { Stack, Text } from "@fluentui/react";
import { Citation, CitationRegistry } from "./CitationRegistry";
import styles from "./Answer.module.css";
import { useState, useEffect } from "react";
import { RAGAgent, getCitationFilePath } from "../../api";

interface CitationsProps {
  isStreaming: boolean;
  onCitationClicked: (filePath: string, callback?: () => Promise<void>) => void;
  conversationId?: string; // Conversation ID to scope citations
  selected_agent?: RAGAgent;
}

export const Citations = ({ 
  isStreaming, 
  onCitationClicked, 
  conversationId, 
  selected_agent
}: CitationsProps) => {
  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(null);

  // Effect to handle styling and scrolling when selectedCitationId changes
  useEffect(() => {
    if (!conversationId) return;
    
    const applyAllCitationStyles = () => {
      // First, reset ALL styling for ALL citations and inline citations across ALL conversations
      const resetElement = (element: Element) => {
        element.getBoundingClientRect();
        if (element instanceof HTMLElement) {
          element.style.fontWeight = 'normal';
        }
        element.classList.remove('selected');
        element.classList.remove(styles.citationSelected);
      };
      
      // Reset all citation, sup, supContainer elements
      document.querySelectorAll(`[id^="citation-"]`).forEach(resetElement);
      document.querySelectorAll('sup').forEach(resetElement);
      document.querySelectorAll('.supContainer').forEach(container => {
        const sup = container.querySelector('sup');
        if (sup) resetElement(sup);
      });
      
      // Apply styles to both the citation list item and inline citations
      if (selectedCitationId) {
        const citationElement = document.querySelector(`#citation-${selectedCitationId}`);
        if (citationElement) {
          citationElement.getBoundingClientRect();
          citationElement.classList.add(styles.citationSelected);
          
          setTimeout(() => {
            if (document.querySelector(`#citation-${selectedCitationId}`)) {
              citationElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 100);
        }
        
        // Style the inline citation
        const inlineCitation = document.querySelector(`#inline-citation-${selectedCitationId} sup`);
        if (inlineCitation) {
          inlineCitation.getBoundingClientRect();
          if (inlineCitation instanceof HTMLElement) {
            inlineCitation.style.fontWeight = 'bold';
          }
          inlineCitation.classList.add('selected');
        }
        
        // Style all matching supContainers
        const matchingContainers = document.querySelectorAll(`.supContainer[data-citation-index="${selectedCitationId}"]`);
        matchingContainers.forEach(container => {
          const sup = container.querySelector('sup');
          if (sup) {
            sup.getBoundingClientRect();
            if (sup instanceof HTMLElement) {
              sup.style.fontWeight = 'bold';
            }
            sup.classList.add('selected');
          }
        });
      }
    };
    
    // Apply styles immediately and after a small delay to ensure DOM is updated
    applyAllCitationStyles();
    const timeoutId = setTimeout(applyAllCitationStyles, 50);
    
    return () => clearTimeout(timeoutId);
  }, [selectedCitationId, conversationId, styles.citationSelected]);
  
  const handleCitationSelection = (citationId: string | null) => {
    setSelectedCitationId(citationId);
    
    if (citationId) {
      setTimeout(() => {
        setSelectedCitationId(null);
      }, 1000); // 1 second delay
    }
  };
  
  const citations = !isStreaming && conversationId 
    ? CitationRegistry.getCitationsForConversation(conversationId).map((citation: Citation) => {
        if (selected_agent === RAGAgent.Legislation && citation.url) {
          return {
            ...citation,
            url: getCitationFilePath(citation.text, selected_agent)
          };
        }
        return citation;
      })
    : [];

  if (isStreaming || citations.length === 0) {
    return null;
  }

  return (
    <Stack.Item>
      <Stack tokens={{ childrenGap: 4 }} style={{ marginLeft: "36px" }} data-conversation-id={conversationId}>
        <Text variant="medium" className={styles.citationHeader} styles={{ root: { fontWeight: 700 } }}>Source:</Text>
        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
          {citations.map((citation: Citation, i: number) => {
            const isSelected = citation.id === selectedCitationId;
            return (
              <a
                id={`citation-${citation.id}`}
                key={citation.id}
                className={`${styles.citation} ${isSelected ? styles.citationSelected : ''}`}
                data-conversation-id={conversationId || citation.conversationId}
                onClick={e => {
                  e.preventDefault();
                  
                  // First, reset all other citations
                  document.querySelectorAll(`[id^="citation-"]`).forEach(elem => {
                    if (elem.id !== `citation-${citation.id}`) {
                      elem.getBoundingClientRect(); // Force a reflow
                      elem.classList.remove(styles.citationSelected);
                    }
                  });
                  
                  // Handle citation selection
                  handleCitationSelection(citation.id);
                  
                  if (citation.url) {
                    // Call the parent's onCitationClicked with the URL
                    onCitationClicked(citation.url, async () => {
                      // Apply styling directly to ensure it's applied
                      const citationElement = document.getElementById(`citation-${citation.id}`);
                      if (citationElement) {
                        citationElement.getBoundingClientRect();
                        citationElement.classList.add(styles.citationSelected);
                      }
                    });
                  }
                }}
              >
                {`${i + 1}. ${citation.text}`}
              </a>
            );
          })}
        </Stack>
      </Stack>
    </Stack.Item>
  );
};
