import { useState, useEffect } from 'react';

/**
 * Custom hook that tracks whether the page is currently visible or hidden
 * using the Page Visibility API.
 * 
 * @returns {boolean} - True if the page is visible, false if hidden
 */
export function usePageVisibility(): boolean {
  // Initialize with the current visibility state
  const [isVisible, setIsVisible] = useState<boolean>(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
}
