import React, { useEffect } from 'react';
import { LoginCallback } from '@okta/okta-react';
import { useOktaAuth } from '@okta/okta-react';
import session from '../session';

export const CustomLoginCallback: React.FC = () => {
  const { authState } = useOktaAuth();

  // Ensure session is started when authentication is successful
  useEffect(() => {
    if (authState?.isAuthenticated) {
      session.start();
    }
  }, [authState]);

  return <LoginCallback />;
};
