import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { initializeIcons } from "@fluentui/react";
import { OktaLogin } from "./forms/oktaLogin";
import "./index.css";
import Layout from "./pages/layout/Layout";
import { OktaLogout } from "./forms/oktaLogout";
import { BASE_PATH } from "./api/";
import { GlobalStyle, gel3Themes } from "@snsw-gel/react";
import { OktaAuthClient } from "./auth/okta";
import { CustomLoginCallback } from "./forms/CustomLoginCallback";

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => window.location.replace(originalUri || window.location.origin + BASE_PATH + "/home");

initializeIcons();

ReactDOM.render(
  <GlobalStyle themes={gel3Themes}>
    <React.StrictMode>
      <Router basename={BASE_PATH}>
        <Security oktaAuth={OktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
          <Route exact path="/" component={OktaLogin} />
          <Route path="/onboarding" component={() => <Redirect to="/home" />} /> {/* Remove this once the browser cache for this route expires.*/}
          <Route path="/authorization-code/callback" component={CustomLoginCallback} />
          <Route path="/logout" component={() => <OktaLogout />} />
          <SecureRoute path="/home" component={Layout} />
        </Security>
      </Router>
    </React.StrictMode>
  </GlobalStyle>,
  document.getElementById("root") as HTMLElement
);
