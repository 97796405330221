import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";
import { SectionPage } from "@snsw-gel/react";
import styles from "./Answer.module.css";
import chatStyles from "../../pages/chat/Chat.module.css";
import { ChatAppResponse, FeedbackData } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { Citation } from "./CitationRegistry";
import SnswAvatar from "../../assets/snswAvatar";
import { RAGAgent } from "../../api/models";
import { Feedback } from "./Feedback";
import { Citations } from "./Citations";
import { marked } from "marked";
import { nanoid } from "nanoid";

interface Props {
  answer: ChatAppResponse;
  isStreaming: boolean;
  showFeedback: boolean;
  selected_agent: RAGAgent;
  getFeedbackData: () => FeedbackData;
  onCitationClicked: (filePath: string, callback?: () => Promise<void>) => void;
  onFeedbackClicked: (sendFeedback: string) => Promise<void>;
  conversationId?: string;
}

interface ParsedAnswer {
  answerHtml: string;
  citations: Citation[];
}

export const Answer = ({ answer, isStreaming, selected_agent, getFeedbackData, onCitationClicked, onFeedbackClicked, showFeedback, conversationId }: Props) => {
  const localConversationId = useRef<string>(conversationId || `conversation-${nanoid()}`);
  const [parsedAnswer, setParsedAnswer] = useState<ParsedAnswer>({ answerHtml: "", citations: [] });
  const annotationContainersRef = useRef<NodeListOf<Element> | null>(null);
  
  useEffect(() => {
    const parseAndSanitize = async (): Promise<void> => {
      const parsedAnswerLocal = parseAnswerToHtml(
        answer.choices[0].message.content, 
        isStreaming, 
        selected_agent, 
        localConversationId.current
      );
      
      const htmlContent = await marked(parsedAnswerLocal.answerHtml); 
      parsedAnswerLocal.answerHtml = sanitizeAndSecureLinks(htmlContent);
      setParsedAnswer(parsedAnswerLocal);
    };

    parseAndSanitize();
  }, [answer, isStreaming, onCitationClicked]);

  useEffect(() => {
    const chatMsgBody = document.querySelector(`.${chatStyles.chatMessageBody}`);

    if (!chatMsgBody) {
      console.warn("Chat container not found!");
      return;
    }

    annotationContainersRef.current = document.querySelectorAll(".supContainer");

    const handleClick = (event: Event) => {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const index = target.getAttribute("data-citation-index");
      if (index) {
        const citation = parsedAnswer.citations.find(citation => citation.id === index);
        if (citation) {
          // First, reset all other inline citations in all conversations
          document.querySelectorAll('sup').forEach(elem => {
            elem.getBoundingClientRect(); // Force a reflow
            if (elem instanceof HTMLElement) {
              elem.style.fontWeight = 'normal';
            }
            elem.classList.remove('selected');
          });
          
          document.querySelectorAll('.supContainer').forEach(container => {
            const sup = container.querySelector('sup');
            if (sup) {
              sup.getBoundingClientRect(); // Force a reflow
              if (sup instanceof HTMLElement) {
                sup.style.fontWeight = 'normal';
              }
              sup.classList.remove('selected');
            }
          });
          
          // Reset all citation elements in the list
          document.querySelectorAll(`[id^="citation-"]`).forEach(elem => {
            elem.getBoundingClientRect(); // Force a reflow
            elem.classList.remove(styles.citationSelected);
          });
          
          // Apply styles directly to the clicked citation
          const supElement = target.querySelector('sup');
          if (supElement) {
            supElement.getBoundingClientRect(); // Force a reflow
            if (supElement instanceof HTMLElement) {
              supElement.style.fontWeight = 'bold';
            }
            supElement.classList.add('selected');
          }
          
          // Scroll to the citation in the list if it exists
          const citationElement = document.getElementById(`citation-${index}`);
          if (citationElement) {
            citationElement.getBoundingClientRect(); // Force a reflow
            citationElement.classList.add(styles.citationSelected);
            citationElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            // If citation element doesn't exist yet, scroll to the clicked element
            chatMsgBody.scrollTo({
              top: target.offsetTop,
              behavior: "smooth"
            });
          }
        }
      }
    };

    annotationContainersRef.current.forEach(annotationContainer => {
      annotationContainer.addEventListener("click", handleClick);
    });

    return () => {
      annotationContainersRef.current?.forEach(annotationContainer => {
        annotationContainer.removeEventListener("click", handleClick);
      });
    };
  }, [parsedAnswer.citations]);

  const sanitizeAndSecureLinks = (htmlContent: string): string => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
      ADD_ATTR: ["target", "data-citation-index", "data-citation-url"]
    });

    const doc = new DOMParser().parseFromString(sanitizedHtml, "text/html");
    return doc.body.innerHTML;
  };

  return (
    <SectionPage className={styles.answerContainer}>
      <Stack>
        <SectionPage className={styles.answerRoleChatGPT}>
          <SnswAvatar />
          <h4 className={styles.answerGPTTitle}>Sage</h4>
        </SectionPage>
      </Stack>

      <Stack.Item>
        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: parsedAnswer.answerHtml }}></div>
      </Stack.Item>

      <Citations 
        isStreaming={isStreaming} 
        conversationId={localConversationId.current}
        selected_agent={selected_agent}
        onCitationClicked={onCitationClicked} 
      />
      {!isStreaming && showFeedback && <Feedback getFeedbackData={getFeedbackData} save={onFeedbackClicked} />}
    </SectionPage>
  );
};
