export type Citation = {
  id: string;
  text: string;
  url?: string;
  conversationId?: string; // Track conversation this citation belongs to
};

export const CitationRegistry = {
  // Map of conversation ID to array of citations
  citationsByConversation: new Map<string, Citation[]>(),
  // Global citations for reference/lookup
  globalCitations: [] as Citation[],
  // Track the currently active citation (conversation ID and citation ID)
  activeCitation: { conversationId: "", citationId: "" },
  
  addOrUpdateCitation(citation: Citation, conversationId: string): void {
    if (!this.citationsByConversation.has(conversationId)) {
      this.citationsByConversation.set(conversationId, []);
    }
    
    const conversationCitations = this.citationsByConversation.get(conversationId)!;
    const existingIndex = conversationCitations.findIndex(c => c.id === citation.id);
    
    if (existingIndex >= 0) {
      conversationCitations[existingIndex] = citation;
    } else {
      conversationCitations.push(citation);
    }
    
    const globalIndex = this.globalCitations.findIndex(c => c.id === citation.id);
    if (globalIndex >= 0) {
      this.globalCitations[globalIndex] = citation;
    } else {
      this.globalCitations.push(citation);
    }
  },
  
  getCitationById(id: string, conversationId: string): Citation | undefined {
    const conversationCitations = this.citationsByConversation.get(conversationId);
    if (conversationCitations) {
      return conversationCitations.find(c => c.id === id);
    }
    return undefined;
  },
  
  getCitationByText(text: string, conversationId: string): Citation | undefined {
    const conversationCitations = this.citationsByConversation.get(conversationId);
    if (conversationCitations) {
      return conversationCitations.find(c => c.text === text);
    }
    return undefined;
  },
  
  getGlobalCitationByText(text: string): Citation | undefined {
    return this.globalCitations.find(c => c.text === text);
  },
  
  getCitationsForConversation(conversationId: string): Citation[] {
    return [...(this.citationsByConversation.get(conversationId) || [])];
  },
  
  clearCitationsForConversation(conversationId: string): void {
    this.citationsByConversation.delete(conversationId);
    if (this.activeCitation.conversationId === conversationId) {
      this.activeCitation = { conversationId: "", citationId: "" };
    }
  },
  
  // Update citations for a specific conversation
  updateCitationsForConversation(newCitations: Citation[], conversationId: string): void {
    this.citationsByConversation.set(conversationId, [...newCitations]);
    
    // Also update global citations
    for (const citation of newCitations) {
      const globalIndex = this.globalCitations.findIndex(c => c.id === citation.id);
      if (globalIndex >= 0) {
        this.globalCitations[globalIndex] = citation;
      } else {
        this.globalCitations.push(citation);
      }
    }
  },
  
  setActiveCitation(citationId: string, conversationId: string): { citationId: string, conversationId: string } {
    const previous = { ...this.activeCitation };
    this.activeCitation = { conversationId, citationId };
    return previous;
  },
  
  clearActiveCitation(): void {
    this.activeCitation = { conversationId: "", citationId: "" };
  },
  
  getActiveCitation(): { citationId: string, conversationId: string } {
    return { ...this.activeCitation };
  }
};
