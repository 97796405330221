import { useState, useRef, useEffect } from "react";
import { ITextField, Stack, TextField } from "@fluentui/react";
import { Button } from "@snsw-gel/react";

import styles from "./QuestionInput.module.css";

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  onFocus?: boolean;
  triggerFocus: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, onFocus, triggerFocus }: Props) => {
  const [question, setQuestion] = useState<string>("");
  const inputRef = useRef<ITextField | null>(null);

  useEffect(() => {
    if (onFocus || triggerFocus) {
      inputRef.current?.focus()
    }
  }, [onFocus, triggerFocus])

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (!newValue) {
      setQuestion("");
    } else if (newValue.length <= 1000) {
      setQuestion(newValue);
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <>
      <Stack horizontal className={styles.questionInputContainer}>
        <TextField
          style={{ fontSize: "18px" }}
          className={styles.questionInputTextArea}
          placeholder={placeholder}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
          componentRef={inputRef}
        />
      </Stack>
      <Button className={styles.questionInputButtonsContainer} children="Ask" onClick={sendQuestion} />
    </>
  );
};
