import readNDJSONStream from "ndjson-readablestream";
import { OktaAuthClient } from "../auth/okta";
import { ChatAppRequest, ChatAppRequestContext, ChatAppResponse, ConversationEntry, ResponseMessage, RetrievalMode, FeedbackData, RAGAgent } from "./models";
import session from "../session";

export const API_HOST = `${import.meta.env.VITE_API_HOST}`;
export const BASE_PATH = `${import.meta.env.VITE_BASE_PATH}`;
async function chatApi(request: ChatAppRequest): Promise<Response> {
  return await post("/chat", request);
}

export async function saveApi(request: Record<string, string>): Promise<Response> {
  return await post("/save", request);
}

export async function feedbackApi(request: { id: string; feedback: FeedbackData }): Promise<Response> {
  return await post("/save/feedback", request);
}

export async function viewsCountApi(request: Record<string, string>): Promise<Response> {
  return await post("/save/views", request);
}

export function getCitationFilePath(citation: string, selected_agent: RAGAgent): string {
  if (selected_agent === RAGAgent.Legislation) {
    const section_number = citation.match(/Section (\d+[a-zA-Z]?)/);
    return `${import.meta.env.VITE_LEGISLATION_WEBSITE_BASE_URL}#sec.${section_number![1]}`;
  }

  return `/data/${citation}`;
}

export async function fetchPDF(url: string) {
  const response = await post(`/${url}`, null);

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await response.blob();
  const [_, pageToRender] = url.split("#page=");
  let blobURL = URL.createObjectURL(blob);
  if (pageToRender) blobURL += `#page=${pageToRender}`;

  return blobURL;
}

const removeDoubleSlashes = (url: string): string => {
  let cleanedUrl = url;
  while (cleanedUrl.includes("//")) {
    cleanedUrl = cleanedUrl.replace("//", "/");
  }
  return cleanedUrl;
};

async function post<T>(url: string, data: T): Promise<Response> {
  return callAPI(url, data, "POST");
}

async function callAPI<T>(url: string, data: T | null = null, method: "POST" | "DELETE" | "PUT" = "POST"): Promise<Response> {
  const isauthenticated = await OktaAuthClient.isAuthenticated();
  if (!isauthenticated) {
    throw new Error("User is not authenticated!");
  }

  const response = await fetch(API_HOST + removeDoubleSlashes(url), {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${OktaAuthClient.getAccessToken()}`
    },
    body: data ? JSON.stringify(data) : undefined
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response;
}

export const saveConversation = (c: ConversationEntry[], q: string): void => {
  const history: { content: string; role: "user" | "assistant" }[] = c.flatMap(m => [
    { content: m[0], role: "user" },
    { content: m[1]?.choices[0]?.message.content ?? "", role: "assistant" }
  ]);

  if (!session.getId()) throw new Error("Session ID cannot be empty");

  // Remove the last item i.e. response from llm as this is to reflect
  // what conversation history/context was passed to llm to determine the last response from llm
  const llm_answer = history.pop()?.content || "";

  const request: Record<string, any> = {
    id: c.length ? c[c.length - 1][1]?.choices[0]?.id : null,
    session_id: session.getId(),
    user_question: q,
    selected_agent: c.length ? c[c.length - 1][1]?.selected_agent : null,
    recommendor_response: c.length ? c[c.length - 1][1]?.recommendor_response : null,
    llm_answer,
    msg_history: history
  };

  saveApi(request).then();
};

// Global flag to track if we're processing in the background
let isProcessingInBackground = false;

// Function to set the background processing flag
export const setBackgroundProcessing = (isBackground: boolean) => {
  isProcessingInBackground = isBackground;
};

const handleAsyncRequest = async (
  question: string,
  answers: ConversationEntry[],
  responseBody: ReadableStream<any>,
  onChunk: (updatedAnswers: ConversationEntry[]) => void
): Promise<ConversationEntry[]> => {
  let answer: string = "";
  let askResponse: ChatAppResponse = {} as ChatAppResponse;
  let botResponseId: string = "";
  let selectedAgent: string = "";
  let recommendorResp: string = "";

  const createLatestResponse = (): ChatAppResponse => ({
    ...askResponse,
    choices: [
      {
        ...askResponse.choices[0],
        message: {
          content: answer,
          role: askResponse.choices[0]?.message.role
        }
      }
    ]
  });

  const updateState = (newContent: string): Promise<void> => {
    return new Promise(resolve => {
      answer += newContent;
      const latestResponse = createLatestResponse();
      
      if (isProcessingInBackground) {
        // Process immediately without delay for background tabs
        onChunk([...answers, [question, latestResponse]]);
        resolve();
      } else {
        // Process with a small delay by allowing the browser to batch updates
        setTimeout(() => {
          onChunk([...answers, [question, latestResponse]]);
          resolve();
        }, 5);
      }
    });
  };

  try {
    for await (const event of readNDJSONStream(responseBody)) {
      if (event["choices"] && event["choices"][0] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
        event["choices"][0]["message"] = event["choices"][0]["delta"];
        askResponse = event;
      } else if (event["choices"] && event["choices"][0]?.["delta"]?.["content"]) {
        await updateState(event["choices"][0]["delta"]["content"]);
      } else if (event["choices"] && event["choices"][0]?.["context"]) {
        // Update context with new keys from latest event
        askResponse.choices[0].context = {
          ...askResponse.choices[0].context,
          ...event["choices"][0]["context"]
        };
      } else if (event["object"] === "coai.llm.botresponse.id") {
        botResponseId = event["id"];
        selectedAgent = event["selected_agent"];
        recommendorResp = event["recommendor_response"];
      } else if (event["error"]) {
        throw new Error(event["error"]);
      }
    }
  } catch (e) {
    console.error("Error in handleAsyncRequest:", e);
  }

  const fullResponse: ChatAppResponse = {
    ...createLatestResponse(),
    choices: [
      {
        ...askResponse.choices[0],
        message: {
          content: answer,
          role: askResponse.choices[0]?.message.role
        },
        id: botResponseId,
        sessionId: session.getId() ?? ""
      }
    ],
    selected_agent: selectedAgent as RAGAgent,
    recommendor_response: recommendorResp
  };

  return [...answers, [question, fullResponse]];
};

export const makeApiRequest = async (
  conversation: ConversationEntry[],
  question: string,
  onChunk: (updatedAnswers: ConversationEntry[]) => void
): Promise<ConversationEntry[]> => {
  const messages: ResponseMessage[] = conversation.flatMap(a => [
    { content: a[0], role: "user" },
    { content: a[1]?.choices[0]?.message.content ?? "", role: "assistant" }
  ]);

  const context: ChatAppRequestContext = {
    overrides: {
      top: 3,
      retrieval_mode: RetrievalMode.Hybrid,
      semantic_ranker: true,
      semantic_captions: false,
      suggest_followup_questions: false,
      use_oid_security_filter: false,
      use_groups_security_filter: false
    }
  };
  const request: ChatAppRequest = {
    sessionId: session.getId() ?? "",
    messages: [...messages, { content: question, role: "user" }],
    stream: true,
    context,
    session_state: conversation.length ? conversation[conversation.length - 1][1]?.choices[0]?.session_state : null
  };

  const response = await chatApi(request);
  return handleAsyncRequest(question, conversation, response.body as ReadableStream<any>, onChunk);
};
